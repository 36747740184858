@charset "utf-8";
@import "../mixin";

/*---------------------------------------------
Top Style
---------------------------------------------*/
body#top {

  .button > a, .button > span{
    margin: 0 auto;
  }

  .mv {

    h2 {
        font-family: 'A-OTF-A1MinchoStd-Bold';
        font-size: 28px;
        line-height: 1.4em;
        font-weight: bold;
        position: absolute;
        color: #FFF;
        @include SP2{
            font-size: 20px;
            line-height: 1.195652174em;
        };
        span, p {
          font-family: 'A-OTF-A1MinchoStd-Bold';
          font-size: 28px;
          line-height: 1.261904762em;
          color: #FFF;
          @include SP{
            font-size: 20px;
            line-height: 1.195652174em;
          };
        }
      }

    &_content {
      height: 100%;
      position: relative;

      &.typeA{
          h2{
              top: 50%;
              left: 80px;
              transform: translateY(-50%);
              @include SP{
                  left: 29px;
                  top: 29px;
                  transform: none;
              }
          }
      }
      &.typeB{

          h2{
              top: 18%;
              right: 350px;
              @include SP2{
                  top: 35px;
                  right: 35px;
              };
              color: #000;
              p, span{
                color: #000;
              }
          }
      }
      &.typeC{

          h2{
              left: 70px;
              top: 28%;

              @include SP{
                  left: 20px;
                  top: auto;
                  bottom: 23px;
              };
          }
      }
    }
  }

  .sec {
    &_sub {
      font-size: 16px;
      line-height: 1.5em;
      text-align: justify;
      font-weight: 500;
      @include SP{
          line-height: 1.4375em;
      };
    }
  }

  .inner {
    max-width: 1150px;
    padding: 0 30px;
    margin: 0 auto;
    @include SP{
        max-width: inherit;
        padding: 0 20px;
    };
  }

  @mixin sec_title($color: #000, $circle: #235dac) {
    font-size: 24px;
    line-height: 1.916666667em;
    position: relative;
    font-weight: 500;
    padding-left: 23px;
    color: $color;
    font-family: 'Noto Sans JP', sans-serif;

    @include SP{
        font-size: 20px;
        line-height: 1.15em;;
    };
    &:before {
      content: "";
      width: 18px;
      height: 18px;
      border-radius: 50%;
      border: $circle solid 4px;
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-40%);
      transform: translateY(-40%);
      @include SP{
          width: 15px;
          height: 15px;
          border: $circle solid 3px;
      };
    }
  }

  .training {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    @include SP{
      display: block;
    };
    * {
      color: #fff;
    }

    > a{
      width: 100%;
      &:hover ~ .training_txt > .button > span{
        background-color: #FFF;
        color: #000 !important;
      }
    }

    .sec_title {
      @include sec_title(#fff, #fff);
    }

    &_img {
      width: 50%;
      background-image: url(../../images/top/training.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      @include SP{
          width: 100%;
          height: 40vmin;
      };
    }
    &_txt {
      width: 50%;
      padding: 25px 40px 50px 60px;
      background-color: #333333;
      @include SP{
          width: 100%;
          padding: 20px;
      };

    }
  }

  .info {
    margin: 25px 0;
    @include SP{
        margin: 48px 0 30px;
    };

    .sec_title {
      @include sec_title;
      @include max-screen(1200px) {
        font-size: 18px;
      }
      @include SP{
          margin-left: 20px;
      };
    }

    .inner {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      @include max-screen(1200px) {
        padding: 0 10px;
      }
      @include SP{
          display: block;
          padding: 0;
      };
    }

    .sec {
      width: calc((100% - 20px) / 3);
      @include SP{
          width: 100%;
      };
    }

    .button {
      text-align: center;
      @include SP{
          margin-bottom: 48px;
      };
    }


    .list {
      margin: 30px 0;
      @include SP{
          margin: 20px 0 30px;
      };

      > li {
        border-top: #cccccc solid 1px;
        &:nth-child(4) {
          border-bottom: #cccccc solid 1px;
        }
        > a {
          display: block;
          padding: 10px 0 10px 15px;
          position: relative;
          -webkit-transition: all 0.3s;
          transition: all 0.3s;
          @include SP{
              padding: 10px 0px 10px 27px;
          };
          &:after {
            content: "";
            width: 8px;
            height: 8px;
            position: absolute;
            top: 50%;
            right: 20px;
            -webkit-transform: rotateZ(-45deg) translateY(-50%);
            transform: rotateZ(-45deg) translateY(-50%);
            border-right: 1px solid #000;
            border-bottom: 1px solid #000;
          }

          @include PC{
              &:hover {
                background-color: #ccd9eb;
              }
          };
        }
      }

      &_date {
        font-size: 14px;
        letter-spacing: 0.04em;
        margin-right: 25px;
        @include SP{
            margin-right: 10px;
        };
      }

      p {
        font-size: 14px;
        line-height: 1.642857143em;
        letter-spacing: 0.04em;
        margin-top: 5px;
        padding-right: 50px;
        @include SP{
            padding-right: 40px;
            line-height: 1.571428571em;
        };
      }
    }
  }

  .new {
    background-color: #fff;
    padding: 30px 0;
    @include SP{
        padding: 25px 0;
        margin: 0 20px;
    };

    .inner{
      position: relative;
    }

    .sec_title {
      @include sec_title;
      margin-bottom: 20px;
    }

    p{
        @include SP{
            font-size: 14px;
            line-height: 1.642857143em;
            letter-spacing: 0.08;
        };
    }

    small {
      font-size: 12px;
      letter-spacing: 0.04em;
      display: inline-block;
      margin: 10px 0 20px;
      @include SP{
          margin: 10px 0 30px;
      };
    }

    .button{
      @include PC{
        position: absolute;
        top: 10px;
        right: 30px;
      };
      @include SP{
        margin-top: 40px;
        position: relative;
        z-index: 5;
      };
    }

    .content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      position: relative;

      > a{
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;

        @include PC{
          &:hover{
            & ~ .content_img, & ~ .content_txt{
              opacity: .7;
            }
          }
        };
      }

      @include SP{
          display: block;
      };

      &_img {
        width: 330px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        transition: all .3s;
        img {
          width: 100%;
        }
        @include SP{
            width: 100%;
        };
      }
      &_txt {
        padding-left: 40px;
        transition: all .3s;
        @include SP{
            padding-left: 0;
            padding-top: 20px;
        };
        time{
            font-size: 12px;
            letter-spacing: 0.04em;
            color: #666666;
        }
        h4 {
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0.04em;
          line-height: 1.277777778em;
          padding-bottom: 10px;
          border-bottom: #235dac solid 1px;

          @include SP{
              font-size: 16px;
              line-height: 1.375em;
              padding: 8px 0 16px;
          };

          &:after{
              content: '\f1c1';
              font-family : 'Font Awesome 5 Free';
              font-weight: 300;
              font-size: 16px;
              margin-left: 15px;
              @include SP{
                  font-size: 16px;
              };
          }

        }
        p {
          font-size: 14px;
          letter-spacing: 0.04em;
          padding-right: 30px;
          margin: 10px 0 20px;
          text-align: justify;
          line-height: 1.571428571em;
          @include SP{
              font-size: 12px;
              letter-spacing: 0;
              line-height: 1.75em;
              padding-right: 0;
              margin: 15px 0;
          };
        }
        .note {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          margin-bottom: 25px;
          @include SP{
              display: block;
              margin-bottom: 0;
          };

          &_name {
            font-size: 16px;
            letter-spacing: 0.04em;
            font-weight: bold;
            @include SP{
                display: block;
                width: 100%;
                font-size: 14px;
            };
          }
          &_date {
            font-size: 14px;
            letter-spacing: 0.04em;
            @include SP{
                display: block;
                width: 100%;
                text-align: right;
                font-size: 12px;
                margin-top: 20px;
            };
          }
        }
      }
    }
  }

  .system {
    background-image: url(../../images/top/system-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 40px 0 60px;
    margin: 20px 0;
    @include SP{
        margin: 25px 20px;
        padding: 30px 25px 33px;
    };

    .inner{
        @include SP{
            padding: 0;
        };
    }

    .sec_title {
      @include sec_title(#fff);
    }

    .item_wrapper {
      margin-top: 40px;
      @include SP{
          margin-top: 22px;
      };
      &:after{
        content: '';
        clear: both;
        display: table;
      }
    }

    .item {
      height: 180px;
      width: calc((100% - 90px) / 4);
      background-color: #fff;
      margin-top: 30px;
      display: block;
      position: relative;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      margin-left: 30px;
      float: left;
      @include PC{
        &:nth-child(3n + 1){
          margin-left: 0;
        }
      };

      @include max-screen(1200px){
          width: calc((100% - 30px) / 4);
          margin-left: 10px;
      };

      @include SP{
          height: 100px;
          width: calc((100% - 15px) / 2);
          margin-top: 20px;
          margin-left: 15px;

          &:nth-child(3n + 1){
            margin-left: 0;
          }
          &:nth-child(-n + 3) {
            margin-top: 0;
          }
      };

      @include PC{
          &:hover {
            background-color: #ccd9eb;
        }

        &:nth-child(-n + 3) {
            margin-top: 0;
        }
      };

      &_icon {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: #235dac;
        margin: 28px auto 15px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #fff;
        font-family: "Font Awesome 5 Free";
        font-weight: 300;
        font-size: 40px;
        @include ie{
            padding: 5px 0 0 3px;
        };

        @include SP{
            width: 40px;
            height: 40px;
            font-size: 20px;
            margin: 15px auto 5px;
        };
      }
      h4 {
        font-size: 20px;
        line-height: 1.4em;
        text-align: center;
        font-family: 'Noto Sans JP', sans-serif;
        @include SP{
            font-size: 12px;
            line-height: 1.916666667em;
        };
        @include max-screen(1300px){
            font-size: 18px;
        };
         @include max-screen(1200px){
            font-size: 16px;
        };
        @include max-screen(1100px){
            font-size: 14px;
        };
         @include max-screen(1000px){
            font-size: 12px;
            line-height: 1.2;
        };
      }
      &_arrow {
        width: 20px;
        height: 20px;
        background-color: #fff;
        border: #235dac solid 1px;
        display: block;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        bottom: -10px;

        &:after {
          content: "";
          width: 7px;
          height: 7px;
          position: absolute;
          left: 4px;
          top: 50%;
          -webkit-transform: translateY(-50%) rotateZ(-45deg);
          transform: translateY(-50%) rotateZ(-45deg);
          border-right: 1px solid #235dac;
          border-bottom: 1px solid #235dac;
        }
      }
    }
  }

  .strength {
    min-height: 340px;
    padding-top: 25px;
    padding-bottom: 30px;
    padding-left: calc(54.8% + 35px);
    position: relative;

    @include PC{
        background-image: url(../../images/top/strength-bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    };

    @include SP{
        margin: 0 20px 25px;
        padding: 175px 20px 30px;
        position: relative;
        background-color: #214e6f;

        &:before{
            content: '';
            width: 100%;
            height: 175px;
            background-image: url(../../images/top/strength-bg-sp.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            position: absolute;
            top: 0;
            left: 0;
        }
    };

    * {
      color: #fff;
    }

    > a{
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      &:hover ~ .button > span{
        background-color: #FFF;
        color: #000 !important;
      }
      z-index: 2;
    }

    .sec_title {
      @include sec_title(#fff, #fff);
      margin-bottom: 10px;

      @include SP{
          margin: 18px 0 15px;
      };
    }
    .sec_sub{
         padding-right: 20px;
         font-weight: bold;
         @include SP{
            padding-right: 0;
         };
    }
    p {
      font-size: 14px;
      letter-spacing: 0.04em;
      line-height: 1.571428571em;
      text-align: justify;
      max-width: 420px;
      padding-right: 20px;
      margin: 10px 0 20px;
      @include SP{
          padding: 10px 0 20px;
          margin: 0;
          line-height: 1.5em;;
          max-width: inherit;
      };
    }
  }

  .fixed_btn_area{
    position: fixed;
    z-index: 1001;
    bottom: 125px;
    right: 0;
    background: #fff;

    .fixed_btn{
      width: 360px;
      display: block;
      transition: 0.3s;
      @include PC {
        &:hover{
          opacity: 0.7;
        }  
      }
      @include SP {
        width: 180px;  
        bottom: 100px;
      }
    }
    .fixed_btn_close{
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #333;
      display: block;
      position: absolute;
      right: 0;
      top: -15px;
      z-index: 1002;
      cursor: pointer;
      &:before,&:after{
        content: '';
        background: #fff;
        display: block;
        height: 1px;
        width: 18px;
        transform: translate(-50%,-50%) rotate(-45deg);
        position: absolute;
        top: 50%;
        left: 50%;
      }
      &:after{
        transform: translate(-50%,-50%) rotate(45deg);
      }
    }
  }
  

}
